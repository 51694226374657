.swiper {
  width: 100%;
  overflow-x: hidden;
  margin: 2rem 0;
}

.swiper-slide {
  background: transparent;
  position: relative;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.link--text {
  text-decoration: none;
  display: flex;
  align-items: center;
}