@import '/src/styles/colors.scss';
@import "sessionize-colors";
@import "2024.scss";

root {
  scroll-padding-top: 240px;
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Square 721 Regular";
  src: url('./fonts/Square 721 Regular.otf');
  font-display: swap;
}

@font-face {
  font-family: 'DejaVu Sans Bold';
  src: url('./fonts/DejaVu Sans Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'DejaVu Sans Condensed Bold';
  src: url('./fonts/DejaVu Sans Condensed Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'DejaVu Sans ExtraLight';
  src: url('./fonts/DejaVu Sans ExtraLight.ttf');
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: '"Square 721 Regular"', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 1rem;
  overflow-x: hidden;
  background: #fffcf9;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

p {
  font-family: "Square 721 Regular", sans-serif;
}

.sz-session--service .sz-session__card {
  color: #8ec9ee;
}